import React, { useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useRouter } from 'next/router';
import {
  Button,
  Heading,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Typography,
} from '@kiiwi/ui';
import ArrowBack from '@mui/icons-material/ArrowBack';
import * as Tabs from '@radix-ui/react-tabs';

import { UpsellDTO } from '@maiia/model/generated/model/api-pro/api-pro';
import { usePost as usePostContactUpsellMail } from '@maiia/model/generated/model/api-pro/hooks/proContactsUpsell';

import { useTranslation } from '../../i18n';

import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';
import { GACategories, GAUpsellActions } from '@/src/constants/tracking';
import { useSelector } from '@/src/hooks/redux';
import { useCurrentUser } from '@/src/hooks/selectors';
import { useAnalyticsTracker, useEnqueueSnack } from '@/src/hooks/utils';
import { unpack } from '@/src/utils';

export const useSendUpsellMail = ({
  onSuccess,
  onError,
  shouldDisplaySnackbar = true,
}: {
  onSuccess?: () => void;
  onError?: () => void;
  shouldDisplaySnackbar?: boolean;
}) => {
  const connectedUser = useCurrentUser();
  const selectedPractitioner = useSelector(state => state.practitioners.item);
  const selectedCenter = useSelector(state => state.centers.item);
  const { enqueueSnack } = useEnqueueSnack();
  const { t } = useTranslation();
  const { mutate: postContactSupportMail } = usePostContactUpsellMail({});

  const sendMail = (contactReason: UpsellDTO['contactReason']) => {
    const payload: UpsellDTO = {
      practitionerId:
        connectedUser?.userProInformation?.defaultPractitionerId ||
        selectedPractitioner?.id ||
        '',
      centerId:
        connectedUser?.userProInformation?.associatedCenters?.find(
          center => center.isDefaultCenter,
        )?.centerId ||
        selectedCenter?.id ||
        '',
      contactReason,
    };

    postContactSupportMail(
      {
        $payload: payload,
      },
      {
        onSuccess: () => {
          onSuccess?.();
          if (shouldDisplaySnackbar) {
            enqueueSnack({
              message: t('needContactAgenda_premium_mail_sending_success'),
              variant: 'success',
            });
          }
        },
        onError: () => {
          onError?.();
          if (shouldDisplaySnackbar) {
            enqueueSnack({
              message: t('needContactAgenda_premium_mail_sending_error'),
              variant: 'error',
            });
          }
        },
      },
    );
  };

  return { sendMail };
};

const Offers = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const [isFullFeaturesListOpen, setIsFullFeaturesListOpen] = useState(false);
  const moreBlock = useRef<null | HTMLDivElement>(null);
  const trackEvent = useAnalyticsTracker(GACategories.Upsell);
  const contactReason = unpack(
    router.query.contactReason,
  ) as UpsellDTO['contactReason'];

  const onMailSent = () => {
    trackEvent(GAUpsellActions.ClickUpsellValidationContactRequest);
  };

  const { sendMail } = useSendUpsellMail({
    onSuccess: onMailSent,
  });

  const handleContactMaiia = () => {
    sendMail(contactReason);
  };

  const AGENDA_OFFERS_1 = [
    {
      functionality: t('offers_premium_agenda_table_line_1'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_2'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_3'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_4'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_5'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_many_event_duplication'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_6'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_7'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_8'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_9'),
      initial: false,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_omnidoc_teleexpertise'),
      initial: false,
      premium: true,
    },
  ];

  const AGENDA_OFFERS_2 = [
    {
      functionality: t('offers_premium_agenda_table_line_10'),
      initial: true,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_11'),
      initial: true,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_12'),
      initial: true,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_13'),
      initial: true,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_14'),
      initial: true,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_15'),
      initial: true,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_16'),
      initial: true,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_17'),
      initial: true,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_18'),
      initial: true,
      premium: true,
    },
    {
      functionality: t('offers_premium_agenda_table_line_19'),
      initial: true,
      premium: true,
    },
  ];

  useUpdateEffect(() => {
    moreBlock?.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
      inline: 'center',
    });
  }, [isFullFeaturesListOpen]);

  return (
    <div className="m-0 bg-white">
      <div className="mx-auto pt-6 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
        <div className="mb-6 flex items-center">
          <IconButton onClick={() => router.back()} datacy="back_offers">
            <ArrowBack />
          </IconButton>
          <Heading as="h2" variant="subtitle-2">
            {t('back_action_label')}
          </Heading>
        </div>
        <div className="mx-auto grid max-w-screen-lg grid-cols-2 gap-x-16">
          <div>
            <img src="/static/images/agenda-premium.svg" alt="agenda premium" />
          </div>
          <div className="pr-16">
            <Heading as="h2" variant="title-2" className="mb-10">
              {t('offers_premium_agenda_title')}
            </Heading>
            <ul className="text-neutral-primary mb-12 flex flex-col gap-y-2 text-base">
              <li>
                <span className="text-brand-secondary-enabled pr-2">+</span>
                {t('offers_premium_agenda_list_1')}
              </li>
              <li>
                <span className="text-brand-secondary-enabled pr-2">+</span>
                {t('offers_premium_agenda_list_2')}
              </li>
              <li>
                <span className="text-brand-secondary-enabled pr-2">+</span>
                {t('offers_premium_agenda_list_3')}
              </li>
              <li>
                <span className="text-brand-secondary-enabled pr-2">+</span>
                {t('offers_premium_agenda_list_4')}
              </li>
              <li>
                <span className="text-brand-secondary-enabled pr-2">+</span>
                {t('offers_premium_agenda_list_5')}
              </li>
            </ul>
            <Button
              color="macro"
              variant="primary"
              size="lg"
              className="mx-auto"
              onClick={handleContactMaiia}
              datacy="maiia-offers_contact_button"
            >
              {t('offers_premium_agenda_button')}
            </Button>
          </div>
        </div>
        <div>
          <div className="mb-10 mt-16 text-center">
            <Heading as="h2" variant="title-3">
              {t('offers_premium_agenda_tabs_title')}
            </Heading>
          </div>
          <Tabs.Root defaultValue="tab1" className="text-center">
            <Tabs.List
              className="bg-picker-light-grey m-auto mb-8 inline-block rounded-[20px]"
              aria-label="Manage your account"
            >
              <Tabs.Trigger
                className="bg-picker-light-grey focus:bg-brand-primary-disabled data-[state='active']:bg-brand-primary-disabled rounded-[20px] px-4 py-2 data-[state='active']:font-semibold"
                value="tab1"
                datacy="upsell-premium_feature_tab-trigger-1"
              >
                {t('offers_premium_agenda_tab_1_title')}
              </Tabs.Trigger>
              <Tabs.Trigger
                className="bg-picker-light-grey focus:bg-brand-primary-disabled data-[state='active']:bg-brand-primary-disabled rounded-[20px] px-4 py-2 data-[state='active']:font-semibold"
                value="tab2"
                datacy="upsell-premium_feature_tab-trigger-2"
              >
                {t('offers_premium_agenda_tab_2_title')}
              </Tabs.Trigger>
              <Tabs.Trigger
                className="bg-picker-light-grey focus:bg-brand-primary-disabled data-[state='active']:bg-brand-primary-disabled rounded-[20px] px-4 py-2 data-[state='active']:font-semibold"
                value="tab3"
                datacy="upsell-premium_feature_tab-trigger-3"
              >
                {t('offers_premium_agenda_tab_3_title')}
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content
              className="mx-auto grid max-w-[796px] grid-cols-2 items-center gap-x-8"
              value="tab1"
              datacy="upsell-premium_feature_tab-content-1"
            >
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-1.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_1_show_profile_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_1_show_profile_text')}
                </Typography>
              </div>
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-2.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_1_appointment_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_1_appointment_text')}
                </Typography>
              </div>
            </Tabs.Content>
            <Tabs.Content
              className="mx-auto grid max-w-[796px] grid-cols-2 items-center gap-8"
              value="tab2"
              datacy="upsell-premium_feature_tab-content-2"
            >
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-6.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_2_notification_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_2_notification_text')}
                </Typography>
              </div>
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-4.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_2_share_document_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_2_share_document_text')}
                </Typography>
              </div>
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-3.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_2_warn_delay_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_2_warn_delay_text')}
                </Typography>
              </div>
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-5.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_2_instructions_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_2_instructions_text')}
                </Typography>
              </div>
            </Tabs.Content>
            <Tabs.Content
              className="mx-auto grid max-w-[796px] grid-cols-2 items-center gap-8"
              value="tab3"
              datacy="upsell-premium_feature_tab-content-3"
            >
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-7.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_3_referral_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_3_referral_text')}
                </Typography>
              </div>
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-8.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_3_waiting_list_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_3_waiting_list_text')}
                </Typography>
              </div>
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-7.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_3_right_management_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_3_right_management_text')}
                </Typography>
              </div>
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-9.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t(
                    'offers_premium_agenda_tab_3_many_event_duplication_title',
                  )}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_3_many_event_duplication_text')}
                </Typography>
              </div>
              <div className="bg-surface-lighter flex h-80 flex-col items-center rounded-2xl p-6">
                <img src="/static/images/bg-offre-maiia-10.svg" alt="" />
                <Heading
                  as="h2"
                  className="text-brand-secondary-enabled mb-4 mt-5"
                  variant="subtitle-1"
                >
                  {t('offers_premium_agenda_tab_3_omnidoc_teleexpertise_title')}
                </Heading>
                <Typography variant="body-2">
                  {t('offers_premium_agenda_tab_3_omnidoc_teleexpertise_text')}
                </Typography>
              </div>
            </Tabs.Content>
          </Tabs.Root>
        </div>
        <div className="m-16 text-center">
          <Heading as="h2" variant="title-3">
            {t('offers_premium_agenda_table_title')}
          </Heading>
        </div>
        <div ref={moreBlock} className="pb-24">
          <Table className="[&_th]:py-0">
            <TableHeader>
              <TableRow className="first:border-none">
                <TableHead>{t('offers_premium_agenda_table_th_1')}</TableHead>
                <TableHead>
                  <div className="bg-brand-primary-enabled rounded-t-lg px-4 py-3.5 text-center text-white">
                    <Heading as="h2" variant="title-3">
                      {t('offers_premium_agenda_table_th_2')}
                    </Heading>
                  </div>
                </TableHead>

                <TableHead>
                  <div className="rounded-t-lg bg-gradient-to-r from-[#C483F6] to-[#FB3B7C] px-4 py-3.5 text-center text-white">
                    <Heading as="h2" variant="title-3">
                      {t('offers_premium_agenda_table_th_3')}
                    </Heading>
                  </div>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {AGENDA_OFFERS_1.map(item => (
                <TableRow key={item.functionality}>
                  <TableCell>{item.functionality}</TableCell>
                  <TableCell>
                    {!item.initial ? (
                      <FontAwesomeIcon
                        name="xmark:regular"
                        className="text-danger-alert-icon mx-auto h-6 w-6"
                      />
                    ) : (
                      <FontAwesomeIcon
                        name="badge-check:solid"
                        className="text-brand-secondary-enabled mx-auto h-6 w-6"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {item.premium ? (
                      <FontAwesomeIcon
                        name="badge-check:solid"
                        className="text-brand-secondary-enabled mx-auto h-6 w-6"
                      />
                    ) : (
                      <FontAwesomeIcon
                        name="xmark:regular"
                        className="text-danger-alert-icon mx-auto h-6 w-6"
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow className="center border-none">
                <TableCell colSpan={3}>
                  <div className="flex items-center justify-center ">
                    <Button
                      variant="ghost"
                      className="flex items-center justify-center gap-1 py-4"
                      onClick={() => {
                        setIsFullFeaturesListOpen(prev => !prev);
                      }}
                    >
                      {t('offers_premium_agenda_table_select')}
                      <span className=" flex h-3 w-3 items-center">
                        {isFullFeaturesListOpen ? (
                          <FontAwesomeIcon
                            name="chevron-up:regular"
                            className="h-3 w-3"
                          />
                        ) : (
                          <FontAwesomeIcon
                            name="chevron-down:regular"
                            className="h-3 w-3"
                          />
                        )}
                      </span>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
              {isFullFeaturesListOpen &&
                AGENDA_OFFERS_2.map(item => (
                  <TableRow key={item.functionality}>
                    <TableCell>{item.functionality}</TableCell>
                    <TableCell>
                      {!item.initial ? (
                        <FontAwesomeIcon
                          name="xmark:regular"
                          className="text-danger-alert-icon mx-auto h-6 w-6"
                        />
                      ) : (
                        <FontAwesomeIcon
                          name="circle-check:solid"
                          className="text-brand-secondary-enabled mx-auto h-6 w-6"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {item.premium ? (
                        <FontAwesomeIcon
                          name="badge-check:solid"
                          className="text-brand-secondary-enabled mx-auto h-6 w-6"
                        />
                      ) : (
                        <FontAwesomeIcon
                          name="xmark:regular"
                          className="text-danger-alert-icon mx-auto h-6 w-6"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Offers;
