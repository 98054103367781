/* eslint-disable import/no-cycle */
import {
  Ref,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useList } from 'react-use';
import { useRouter } from 'next/router';
import { FormikProps } from 'formik';
import noop from 'lodash/noop';
import moment, { Moment } from 'moment';
import { useDebouncedCallback } from 'use-debounce';

import { encode } from 'querystring';

import {
  AppointmentGetDTO,
  ConsultationReason,
  ConsultationReasonDTO,
  Patient,
  PatientGetDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import {
  FILE_SIZE,
  SUPPORTED_FORMATS,
} from '../../components/FormikSchema/DocumentUploadSchema';
import { formatAutoCompleteInput } from '../../components/utils/tools';
import { useTranslation } from '../../i18n';
import { Dictionary, FormattedAppointment } from '../../types/pro.types';
import { documentsActions, patientsActions, snacksActions } from '../actions';
import config from '../config';
import {
  DEFAULT_MAX_AGE,
  DEFAULT_MIN_AGE,
  GA_EVENT_CATEGORIES,
  PATIENT_MENTION_SUGGESTIONS_LIMIT,
} from '../constants';
import {
  GAActionStatus,
  GAActionsType,
  GACategories,
  GALabelsType,
  TrackUserEvent,
} from '../constants/tracking';
import { getUnitCountForAge } from '../utils';
import { useDisplayTimes } from './dateFormat';
import { useGlobalFeatureFlags } from './featureFlags';
import { useCenterId } from './selectors';
import { usePatientLimitationsStyles } from './styles';

import { UseIntersectionObserverConfig } from '@/src/contexts';
import { useSelector } from '@/src/hooks/redux';

export const useIsProductionEnvironment = () => {
  const isProductionEnvironment = config.get('ENVK8S') === 'production';
  return isProductionEnvironment;
};

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: Function, delay: number) => {
  const savedCallback = useRef<Function>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect((): (() => void) => {
    const handler = (...args: any[]) =>
      savedCallback.current && savedCallback.current(...args);

    if (delay !== null) {
      const id = setInterval(handler, delay);
      return () => {
        clearInterval(id);
      };
    }
    return noop;
  }, [delay]);
};
type UsePositionConfig<T> = {
  startAccessor?: (option: T) => string;
  endAccessor?: (option: T) => string;
  date: Moment;
  item: T;
  getCurrentTimePosition: (date: Date) => number;
};

export const usePosition = <T extends {}>(params: UsePositionConfig<T>) => {
  const {
    startAccessor = (option: Dictionary<string>) => option.startDate,
    endAccessor = (option: Dictionary<string>) => option.endDate,
    date,
    item,
    getCurrentTimePosition,
  } = params;

  const [displayStartTime, displayEndTime] = useDisplayTimes();

  const dateTopRef = displayStartTime
    .set({ date: date.date(), month: date.month(), year: date.year() })
    .toDate();
  const dateBottomRef = displayEndTime
    .set({ date: date.date(), month: date.month(), year: date.year() })
    .toDate();

  const dateTop = moment(startAccessor(item)).toDate();
  const dateBottom = moment(endAccessor(item)).toDate();

  const topPercent = getCurrentTimePosition(
    dateTop.getTime() < dateTopRef.getTime() ? dateTopRef : dateTop,
  );
  const bottomPercent = getCurrentTimePosition(
    dateBottom.getTime() > dateBottomRef.getTime() ? dateBottomRef : dateBottom,
  );

  return [topPercent, bottomPercent];
};

export const useUpdatedState = (stateFromProps: any) => {
  const [state, setState] = useState(stateFromProps);

  useEffect(() => {
    setState(stateFromProps);
  }, [stateFromProps]);

  return [state, setState];
};

export const useIntersectionObserver = (
  useIntersectionObserverConfig: UseIntersectionObserverConfig,
) => {
  const {
    root,
    target,
    onIntersect,
    threshold = 1.0,
    rootMargin = '0px',
  } = useIntersectionObserverConfig;
  useEffect(() => {
    if (!root) {
      // eslint-disable-next-line no-console
      console.warn(
        `No "root" specified for Intersection Observer.
useIntersectionObserver needs to be called with an initial configuration,
where you'd pass in a "root" value (a React Ref using the useRef hook)
to an element that contains a child that would intersect with it: a
container that's aware of its children.
Please add a root option and try again.
More info: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Creating_an_intersection_observer`,
      );
      return;
    }

    const observer = new IntersectionObserver(onIntersect, {
      root: root.current,
      rootMargin,
      threshold,
    });

    if (!target) {
      // eslint-disable-next-line no-console
      console.warn(
        `No target specified for Intersection Observer.
useIntersectionObserver needs to be called with an initial configuration,
where you'd pass in a "target" value (a React Ref using the useRef hook) that
represents an element that is contained inside a root element that will be
tracked.
Please add a target option and try again.
More info: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Creating_an_intersection_observer`,
      );
      return;
    }
    if (target.current) observer.observe(target.current);

    // eslint-disable-next-line consistent-return
    return () => {
      if (target.current) observer.unobserve(target.current);
    };
  });
};

export const usePatientAgeValidation = (
  values: {
    consultationReason: ConsultationReasonDTO | null;
    patient: PatientGetDTO | null;
  },
  allowedLimitations = { isBannedAllowed: true, isAgeAllowed: true },
) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState();
  const { consultationReason, patient } = values;

  useEffect(() => {
    if (patient) {
      const { birthDate } = patient;
      const patientAgeDetails = getUnitCountForAge(birthDate);
      const { minimumAge = DEFAULT_MIN_AGE, maximumAge = DEFAULT_MAX_AGE } =
        consultationReason || {};
      if (patientAgeDetails) {
        const [units, age] = patientAgeDetails;
        let ageInYear = age;
        // If the patient's age is under a year, we keep year zero as a value in this particular case
        if (units === 'age_month' || units === 'age_day') {
          ageInYear = 0;
        }

        if (
          allowedLimitations.isAgeAllowed &&
          (ageInYear < minimumAge || ageInYear > maximumAge)
        ) {
          setMessage(t('form:appointment_form_incompatible_age'));
        }
      }
    }
  }, [patient, consultationReason]);
  return message;
};

export const usePatientLimitations = (
  values: {
    consultationReason: ConsultationReason;
    patient: Patient;
  },
  allowedLimitations = { isBannedAllowed: true, isAgeAllowed: true },
) => {
  const { t } = useTranslation();
  const { classes } = usePatientLimitationsStyles();
  const [
    incompatibilityReasons,
    { clear: clearIncompatibilityReasons, push: pushIncompatibilityReasons },
  ] = useList<string>([]);
  const [
    inputClassNames,
    { clear: clearInputClassNames, push: pushInputClassNames },
  ] = useList<string>([]);

  const { consultationReason, patient } = values;
  useEffect(() => {
    clearIncompatibilityReasons();
    clearInputClassNames();
    if (patient) {
      const { birthDate, isBanned } = patient;
      if (isBanned && allowedLimitations.isBannedAllowed) {
        pushInputClassNames(classes.bannedPatient);
      }
      const patientAgeDetails = getUnitCountForAge(birthDate);
      const { minimumAge, maximumAge } = consultationReason || {};

      if (patientAgeDetails) {
        const [units, age] = patientAgeDetails;
        let ageInYear = age;
        // If the patient's age is under a year, we keep year zero as a value in this particular case
        if (units === 'age_month' || units === 'age_day') {
          ageInYear = 0;
        }
        if (
          allowedLimitations.isAgeAllowed &&
          (ageInYear < (minimumAge || DEFAULT_MIN_AGE) ||
            ageInYear > (maximumAge || DEFAULT_MAX_AGE))
        ) {
          pushIncompatibilityReasons(t('schemas:incompatible_age'));
          pushInputClassNames(classes.incompatiblePatient);
        }
      }
    }
  }, [patient, consultationReason]);
  return [incompatibilityReasons, inputClassNames];
};

export const useWindowSize = () => {
  type WindowSizeConfig = {
    width?: number;
    height?: number;
  };
  const [windowSize, setWindowSize] = useState<WindowSizeConfig>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export enum ScrollDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

export const useScrollDirection = (ref: React.RefObject<HTMLDivElement>) => {
  const [scrollDir, setScrollDir] = useState(ScrollDirection.DOWN);
  const [lastScrollPos, setLastScrollPos] = useState(0);

  const handleScroll = event => {
    if (lastScrollPos > event.currentTarget.scrollTop) {
      setScrollDir(ScrollDirection.UP);
    } else if (lastScrollPos < event.currentTarget?.scrollTop) {
      setScrollDir(ScrollDirection.DOWN);
    }
    setLastScrollPos(event.currentTarget?.scrollTop);
  };
  useEffect(() => {
    ref?.current?.addEventListener('scroll', handleScroll);

    return () => ref?.current?.removeEventListener('scroll', handleScroll);
  }, [ref?.current, lastScrollPos]);

  return scrollDir;
};

export const isPatientConnectedWithLgc = patient =>
  patient?.insData?.ins && patient?.insData?.type && patient?.insData?.oid;

export const useAnalyticsTracker = (category: GACategories) => {
  const trackUserEvent: TrackUserEvent = (
    action: GAActionsType,
    // eslint-disable-next-line unused-imports/no-unused-vars-ts
    _status: GAActionStatus = GAActionStatus.SUCCESS,
    label?: GALabelsType,
    noLabelCheck?: boolean,
    value?: number,
  ) => {
    const selectedCategory = GA_EVENT_CATEGORIES.find(
      cat => cat.name === category,
    );

    const selectedAction = selectedCategory?.actions.find(
      act => act.name === action,
    );

    const selectedLabel = selectedAction?.labels?.find(lab => lab === label);

    if (
      selectedAction != null &&
      selectedCategory != null &&
      (window as any)?._paq
    ) {
      // '_paq' is the matomo tag manager instance that allows us to send events
      // this instance is created & handled by tarteaucitron
      (window as any)._paq.push([
        'trackEvent',
        selectedCategory.name,
        selectedAction.name,
        noLabelCheck ? label?.toString() : selectedLabel?.toString(),
        value,
      ]);
    }
  };

  return trackUserEvent;
};

export const useDocumentUploadDnD = (isDocUploadFromChat = false) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<any>>(null);
  const documentUploadOpenerRef: Ref<HTMLButtonElement> = useRef(null);

  const ACCEPTED_FILES_PATTERN = SUPPORTED_FORMATS.join(', ');

  const onDropAccepted = useCallback(async acceptedFiles => {
    if (!formRef?.current && documentUploadOpenerRef?.current)
      documentUploadOpenerRef?.current?.click();
    const droppedFile = acceptedFiles?.[0];
    const form = formRef?.current;
    const docValues: any = {
      ...form?.values,
      file: droppedFile,
      title: form?.values.title || droppedFile?.name,
      type: droppedFile.type,
      isNewDocument: true,
      isDocUploadFromChat,
    };

    form?.setValues(docValues);
    dispatch(documentsActions.setItem(docValues));
  }, []);

  const onDropRejected = rejectedFiles => {
    const { errors } = rejectedFiles?.[0];
    const errorCode = errors?.[0].code;
    const message =
      errorCode === 'file-too-large'
        ? t('schemas:document_upload__file_size')
        : t('schemas:document_upload_invalid_type_file');
    dispatch(
      snacksActions.enqueueSnack({
        message,
        variant: 'error',
      }),
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxSize: FILE_SIZE,
    accept: ACCEPTED_FILES_PATTERN,
    multiple: false,
    noClick: true,
  });

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    formRef,
    documentUploadOpenerRef,
  };
};

export const usePatientMentionSuggestionsUtils = (
  onAddMentionCallBack?: Function,
  patientSearchLimit = PATIENT_MENTION_SUGGESTIONS_LIMIT,
) => {
  const dispatch = useDispatch();
  const centerId = useCenterId()!;
  const onPatientSearchChange = useCallback(
    (term: string) => {
      dispatch(
        patientsActions.getList({
          centerId,
          limit: patientSearchLimit,
          term,
        }),
      );
    },
    [centerId],
  );

  const onPatientSearchChangeDebounced = useDebouncedCallback(
    onPatientSearchChange,
    300,
  );

  const onAddPatientMention = useCallback(
    // eslint-disable-next-line unused-imports/no-unused-vars-ts
    (_patient?: Patient) => {
      onPatientSearchChangeDebounced('');
      onAddMentionCallBack?.();
    },
    [onPatientSearchChangeDebounced],
  );

  const handleAddMention = useCallback(
    ({ data }) => {
      onAddPatientMention(data);
    },
    [onAddPatientMention],
  );

  const handleMentionSearchChange = useCallback(
    ({ value }) => {
      onPatientSearchChangeDebounced(formatAutoCompleteInput(value));
    },
    [onPatientSearchChangeDebounced],
  );

  return { handleAddMention, handleMentionSearchChange };
};

export const useDuration = (pause: boolean = false) => {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pause) return;
      setDuration(duration + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [duration, pause]);
  return duration;
};

export const useIsTLCAssisted = (appointment: AppointmentGetDTO) => {
  const center = useSelector(store => store.centers.item);
  return (
    center?.id !== appointment.centerId &&
    center?.teleconsultationRelay?.id === appointment.teleconsultationRelayId
  );
};

export const useUserPharmacyId = () => {
  const center = useSelector(store => store.centers.item);
  return center?.teleconsultationRelay?.id;
};

export const useEnqueueSnack = () => {
  const dispatch = useDispatch();
  const enqueueSnack: typeof snacksActions.enqueueSnack = options =>
    dispatch(snacksActions.enqueueSnack(options));
  return { enqueueSnack };
};

/**
 * ⚠️ NOT A REPLACEMENT FOR `useEffectEvent`
 *
 * It's a basic implementation of the `useEffectEvent` hook, currently only available in react 18 experimental.
 *
 * The callback from the `useEffectEvent` should be used inside a `useEffect`.
 *
 * You should only use it in very specific case: When your `useEffect` depends on some dependencies but you don't want to rerun on each change of those dependencies.
 *
 * https://react.dev/learn/separating-events-from-effects#reading-latest-props-and-state-with-effect-events
 */
export function useEffectEvent<T extends CallableFunction>(func: T): T {
  const ref = useRef<T>(func);

  useLayoutEffect(() => {
    ref.current = func;
  });

  return (useCallback(
    (...params) => ref.current(...params),
    [],
  ) as unknown) as T;
}

// TODO: replace with next/router useSearchParams hook after the upgrade to Next13
export function useSearchParams() {
  const { query } = useRouter();
  return new URLSearchParams(encode(query));
}

export function useEncounterMarking(
  event?: FormattedAppointment,
): {
  isMarked: boolean;
  isUnmarked: boolean;
} {
  const { data: featureFlags } = useGlobalFeatureFlags();
  const isEncounterMarkingFeatureFlagEnabled = !!featureFlags?.isEncounterMarking;
  return {
    isMarked:
      isEncounterMarkingFeatureFlagEnabled &&
      !!event &&
      event.encounterStatus === 'MARKED',
    isUnmarked:
      isEncounterMarkingFeatureFlagEnabled &&
      !!event &&
      event.encounterStatus === 'UNMARKED',
  };
}
