import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { CssBaseline } from '@mui/material';
import NoSsr from '@mui/material/NoSsr';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'; // https://mui.com/material-ui/guides/interoperability/#css-injection-order
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SSRCookies, SSRKeycloakProvider } from '@react-keycloak/ssr';
import { KeycloakInitOptions } from 'keycloak-js';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import WaveSurfer from 'wavesurfer.js';

import { UrlObject } from 'url';

import ConfirmAlert from '../components/organisms/ConfirmAlert';
import ConfirmAlertContext from '../components/organisms/ConfirmAlert/ConfirmAlertContext';
import { useTranslation } from '../i18n';
import config from './config';
import MediaDevicesContextProvider from './contexts/MediaDevicesContext/MediaDevicesContextProvider';
import { useIntersectionObserver } from './hooks/utils';
import createTheme from './theme';
import { HEADER_HEIGHT } from './theme/height';

import 'moment/locale/fr';

import LoaderMaiiaView from '@/components/metaOrganisms/loaders/Maiia/views/LoaderMaiiaView';
import { ChatProvider } from '@/src/contexts/ChatContexts/ChatProvider';
import { useMemoizedValues } from '@/src/hooks/chatUtils';

const OpenTokSession = dynamic(
  () => import('../components/organisms/OpenTok/OpenTokSession'),
  { ssr: false },
);

moment.locale('fr');

type Props = {
  cookies: any; // TODO: type
  children: ReactNode;
};
type AudioPlayingInfo = {
  url: string | null;
  ref: WaveSurfer | null;
};

const useChatRouter = () => {
  const router = useRouter();
  const { pathname, query } = router;
  const replace = useCallback(
    (route: UrlObject | string) =>
      router.replace(route, route, { shallow: true }),
    [router.replace],
  );
  return useMemoizedValues({ pathname, query, replace });
};

const OpenTokContext = ({ children }) => {
  const router = useRouter();
  const { pathname } = router;
  const [isLoad, setIsLoad] = useState(false);
  useEffect(() => {
    if (isLoad) return;
    if (pathname === '/tlc') setIsLoad(true);
  }, [pathname]);
  if (isLoad) return <OpenTokSession>{children}</OpenTokSession>;

  return <>{children}</>;
};

const defaultKeycloakConfigUrl = 'https://uaa-integration.cegedim.com/auth';
const defaultKeycloakConfigRealm = 'CHS';

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
  silentCheckSsoRedirectUri:
    typeof window !== 'undefined'
      ? `${window?.location?.origin}/silent-check-sso.html`
      : undefined,
};

const AppProvider = ({ cookies, children }: Props) => {
  const confirmAlertRef = useRef(null);
  const { query } = useRouter();
  const { t } = useTranslation();
  const [hasBeenHiddenChatRoom, setHasBeenHiddenChatRoom] = useState<boolean>(
    false,
  );
  const [audioPlayingInfo, setAudioPlayingInfo] = useState<AudioPlayingInfo>({
    url: null,
    ref: null,
  });
  const [parentIdToRedirectTo, setParentIdToRedirectTo] = useState<
    string | null
  >(null);
  const [childMessageIdToRedirectTo, setChildMessageIdToRedirectTo] = useState<
    string | null
  >(null);

  const { headerVisibility } = query;
  const theme = useMemo(
    () =>
      createTheme({
        headerHeight: headerVisibility === 'hidden' ? 0 : HEADER_HEIGHT,
      }),
    [headerVisibility],
  );

  const keycloakCfg = {
    realm: config.get('KEYCLOAK_CONFIG_REALM') || defaultKeycloakConfigRealm,
    url: config.get('KEYCLOAK_CONFIG_URL') || defaultKeycloakConfigUrl,
    clientId: 'MAIIA-AGENDA',
  };

  return (
    // WARNING: must be set with the lang var
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <SSRKeycloakProvider
            keycloakConfig={keycloakCfg}
            persistor={SSRCookies(cookies)}
            initOptions={keycloakInitOptions}
            LoadingComponent={query?.data ? <LoaderMaiiaView /> : undefined}
            onEvent={event => {
              if (event === 'onAuthLogout') {
                // TODO: problem with private navigation, keycloak init request is sent without cookies so 'onAuthLogout' is fired
                // store.dispatch(
                //   serialActions.serial([
                //     () => logoutActions.create(null),
                //     () => {
                //       window.location.href = '/login';
                //     },
                //   ]),
                // );
              }
            }}
          >
            <MediaDevicesContextProvider>
              <OpenTokContext>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  maxSnack={4}
                >
                  <ConfirmAlert ref={confirmAlertRef} />
                  <ConfirmAlertContext.Provider value={confirmAlertRef}>
                    <NoSsr>
                      <ChatProvider
                        moment={moment}
                        t={t}
                        useRouter={useChatRouter}
                        useIntersectionObserver={useIntersectionObserver}
                        CHAT_PAGINATION_LIMIT={15}
                        redirectOnSelfChatRoomOnChangeCenter
                        isChatRoomVisible
                        hasBeenHiddenChatRoom={hasBeenHiddenChatRoom}
                        setHasBeenHiddenChatRoom={setHasBeenHiddenChatRoom}
                        audioPlayingInfo={audioPlayingInfo}
                        setAudioPlayingInfo={setAudioPlayingInfo}
                        parentIdToRedirectTo={parentIdToRedirectTo}
                        setParentIdToRedirectTo={setParentIdToRedirectTo}
                        childMessageIdToRedirectTo={childMessageIdToRedirectTo}
                        setChildMessageIdToRedirectTo={
                          setChildMessageIdToRedirectTo
                        }
                      >
                        {children}
                      </ChatProvider>
                    </NoSsr>
                  </ConfirmAlertContext.Provider>
                </SnackbarProvider>
              </OpenTokContext>
            </MediaDevicesContextProvider>
          </SSRKeycloakProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};

// AppProvider.whyDidYouRender = true;

export default AppProvider;
