import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Patient } from '@maiia/model/generated/model/api-pro/api-pro';

import { useTranslation } from '../../../i18n';
import { usePractitionerId } from '../../../src/hooks/selectors';
import { getPatientLabel, isMaiiaPatient } from '../../../src/utils';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import Highlight from '../../atoms/Highlight';
import Typography from '../../atoms/Typography';
import FusionIcon from '../../icons/FusionIcon';

import { KnownPatientIcon } from '@/components/molecules/PatientLabel/PatientLabel';
import { useDatacyName } from '@/src/hooks/datacy';

const useStyles = makeStyles<{ isBanned: boolean }>()(
  (theme: any, { isBanned }) => ({
    headerPatientLabelRoot: {
      display: 'flex',
      alignItems: 'center',

      '& > div.merge': {
        '--size': theme.spacing(5),
        height: 'var(--size)',
        width: 'var(--size)',

        '& > svg.MuiSvgIcon-root': {
          height: 'var(--size)',
          width: 'var(--size)',
        },
      },

      '& > *:not(button)': {
        marginRight: theme.spacing(1),
        color: isBanned
          ? theme.palette.destructive.main
          : theme.palette.text.body,
      },
      '& .patientLabel': {
        textDecoration: isBanned ? 'line-through' : 'none',
      },
    },
    btnSyncHeaderLabel: {
      fontSize: theme.size.font.xxs,
      fontWeight: 600,
    },
  }),
);

type Props = {
  searchTerms: Array<string>;
  patient: Patient;
};

const HeaderPatientLabel = (props: Props) => {
  const { searchTerms, patient } = props;
  const {
    referringPractitionerId,
    isBanned,
    origin,
    knownPractitioners,
    mergePatientCandidateIds,
    // syncData,
  } = patient;
  const { classes, cx } = useStyles({ isBanned: !!isBanned });
  const { t } = useTranslation();
  const currentPractitionerId = usePractitionerId() || undefined;
  const patientLabel = getPatientLabel(patient);

  const mergeIconDatacy = useDatacyName('merge_icon');

  const isReferringPractitioner =
    !!referringPractitionerId &&
    !!currentPractitionerId &&
    referringPractitionerId === currentPractitionerId;
  return (
    <div className={cx(classes.headerPatientLabelRoot, 'headerPatientLabel')}>
      <Highlight
        searchTerm={searchTerms}
        text={patientLabel}
        className="patientLabel"
      />
      {!!currentPractitionerId && (
        <KnownPatientIcon
          partialPatient={{
            origin,
            knownPractitioners,
            isBanned,
          }}
          practitionerId={currentPractitionerId}
        />
      )}

      {isMaiiaPatient(patient) && (
        <Typography variant="body" component="span">
          {t('common:complex_patient_label__internet_indicator')}
        </Typography>
      )}
      {isReferringPractitioner && (
        <FontAwesomeIcon name="heart:solid" className="h-5 w-5" />
      )}
      {isBanned && (
        <FontAwesomeIcon name="lock-keyhole:regular" className="h-5 w-5" />
      )}
      {mergePatientCandidateIds && mergePatientCandidateIds.length > 0 && (
        <div className="merge" datacy={mergeIconDatacy}>
          <FusionIcon round />
        </div>
      )}
    </div>
  );
};

HeaderPatientLabel.displayName = 'HeaderPatientLabel';

export default HeaderPatientLabel;
